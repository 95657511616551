import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://c3ce4a5099fb4e011f81af07227fdfd1@o4507519924174848.ingest.de.sentry.io/4507519925747792",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()